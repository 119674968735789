







































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { convertISOtoLocalTime, jsonParse } from "@/utils/helpers";
import accountModule from "@/store/modules/accountModule";
import assetsModule from "@/store/modules/assetsModule";
import scheduleModule from "@/store/modules/scheduleModule";
import { mixins } from "vue-class-component";
import Multiselect from "vue-multiselect";
import workspaceModule from "@/store/modules/workspaceModule";
import { getNameByEmail } from '@/utils/users';

@Component({
  components: {
    Multiselect,
  },
})
export default class ScheduleV2 extends mixins() {
  selectedColumnType = "DETAILS";
  userSelectionVal = "";
  yourValue = "";

  // NEEDED
  refreshResponseDataKey = 0;
  editShiftChecklistPopup = false;
  editShiftIsLoading = false;
  allDay = false;

  // NEEDED
  editShiftGroupSelected = "";

  templateOptions: any[] = [];
  shiftTemplates: any;
  selectedTemplate = -1
  shiftEndTimeOptions: any[] = ["00:00"];
  timeOptions: any[] = [];
  shiftDate = "";

  // Loading views
  dataLoading = false;
  userOptions: any[] = [];
  assetOptions: any[] = [];
  assetType = "";
  errorMessages = { assetType: "", assetName: "", username: "" }
  dataErrorMessage = "";
  userName = "";
  assetName = "";
  allAssetTypes: any;
  shiftStartTime='';
  shiftEndTime='';
  shiftColor = '';
  disableShiftStartTime = false;
  disableShiftEndTime = false;

  get activeComponent() {
    return scheduleModule.activeComponent;
  }

  get activeColumns() {
    return scheduleModule.activeComponentResponseColumns;
  }

  get scheduledRowComponents(): any[] {
      return scheduleModule.scheduledRowComponents
  }

  // NEEDED
  closeEditShiftPopup(component_id, start_date=0, end_date=0) {
    this.$emit("close-edit-shift-popup", component_id, start_date, end_date);
  }


  deleteShiftPopup() {
	  this.activeComponent.component_id = this.activeComponent.ID
	  this.$emit("confirm-delete-shift", this.activeComponent);
  }


  timeToMilitaryTimeString(time) {

    function pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

	  var hour = time.split(":")[0]
	  var minutes = time.split(":")[1]
	  minutes = minutes.replace("am", "")
	  minutes = minutes.replace("pm", "")
	  var meridian = time.includes("am") ? "AM" : "PM"
	  if (meridian == "AM" && hour == 12) {
		  return pad(0, 2, '0') + ":" + pad(minutes, 2, '0')
	  } else if (meridian == "AM") {
		  return pad(hour, 2, '0') + ":" + pad(minutes, 2, '0')
	  } else if (meridian == "PM" && hour == 12) {
		  return pad(12, 2, '0') + ":" + pad(minutes, 2, '0')
	  } else {
		  return pad(parseInt(hour) + 12, 2, '0') + ":" + pad(minutes, 2, '0')
	  }
  }
  

  async editShiftSave() {
    // @ts-ignore

    function pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    var errorFlag = false;
    for (var k in this.errorMessages) {
      this.errorMessages[k] = "";
    }

    if (this.assetType == '') {
      errorFlag = true;
      this.errorMessages.assetType = "Please select asset type"
    }

    if (this.assetName == '') {
      errorFlag = true;
      this.errorMessages.assetName = "Please select asset name"
    }

    if (this.userName == '') {
      errorFlag = true;
      this.errorMessages.username = "Please select a user"
    }

    if (errorFlag)
      return;

    var startTime, endTime, shiftTemplateID;
    if (this.selectedTemplate >= 0) {
      startTime = this.shiftTemplates[this.selectedTemplate].StartTime;
      endTime = this.shiftTemplates[this.selectedTemplate].EndTime;
      scheduleModule.addPersonnelScheduleShiftTemplates({
        id: this.shiftTemplates[this.selectedTemplate].ID,
        name: this.shiftTemplates[this.selectedTemplate].TemplateName,
        color: this.shiftTemplates[this.selectedTemplate].Color,
      })
	  shiftTemplateID = this.shiftTemplates[this.selectedTemplate].ID
    } else {
      if (this.allDay) {
		  var offset = (new Date().getTimezoneOffset()) / 60;

        startTime = "00:00";
        endTime = "23:59";
      } else {
		
        startTime = this.timeToMilitaryTimeString(this.shiftStartTime);
        endTime = this.timeToMilitaryTimeString(this.shiftEndTime);
      }
    }




	var offset = (new Date().getTimezoneOffset()) / 60;
	var originalStartTimeStr = startTime
	var originalEndTimeStr = endTime

	startTime = new Date(this.shiftDate.split("T")[0] + "T" + startTime + ":00")
	endTime = new Date(this.shiftDate.split("T")[0] + "T" + endTime + ":00")
	startTime.setTime(startTime.getTime() + (offset*60*60*1000));
	endTime.setTime(endTime.getTime() + (offset*60*60*1000));
	if (parseFloat(originalEndTimeStr.replace(":", ".")) <= parseFloat(originalStartTimeStr.replace(":", "."))) {
		endTime.setDate(endTime.getDate() + 1);
	}
	startTime.setDate(startTime.getDate() - 1);
	endTime.setDate(endTime.getDate() - 1);

	var start_datetime_string = startTime.getFullYear() + "-" + pad(startTime.getMonth() + 1, 2, '0') + "-" + pad(startTime.getDate(), 2, '0') + "T" + pad(startTime.getHours(), 2, '0') + ":" + pad(startTime.getMinutes(), 2, '0')+ ":" + pad(startTime.getSeconds(), 2, '0');
	var end_datetime_string = endTime.getFullYear() + "-" + pad(endTime.getMonth() + 1, 2, '0') + "-" + pad(endTime.getDate(), 2, '0') + "T" + pad(endTime.getHours(), 2, '0') + ":" + pad(endTime.getMinutes(), 2, '0')+ ":" + pad(endTime.getSeconds(), 2, '0');

	for (var i = 0; i < scheduleModule.totalComponents.length; i++) {
		// for (var p = 0; p < scheduleModule.filterScheduledRowComponents[i].rows.length; p++) {
			// for (var b = 0; b < scheduleModule.filterScheduledRowComponents[i].rows[p].components.length; b++) {
		if (this.activeComponent.ID == scheduleModule.totalComponents[i].ID) {
			scheduleModule.setFilterScheduledComponentDetails({component_index: i, start_date: start_datetime_string, end_date: end_datetime_string, well: this.assetName, username: this.userName, asset_type: this.assetType, shift_template_id: shiftTemplateID, row_id: this.assetName})
			break
		}

	}
	
	// scheduleModule.filterScheduledRowComponents[i].rows[p].components[b].assignee.selection_id

    // scheduleModule.addPersonnelScheduleShifts({
    //   type: 'edit',
    //   username: this.userName,
    //   startTime: startTime,
    //   endTime: endTime,
  	// 	assetType: this.assetType,
	//   	asset: this.assetName,
    //   // color: this.shiftColor,
    //   RowID: this.activeComponent.RowID,
    //   ShiftID: this.activeComponent.ID
    // })
    // @ts-ignore
    this.closeEditShiftPopup(this.activeComponent.ID, startTime, endTime);
  }

  editShiftResponseData(editShiftResponseData: any) {
    throw new Error("Method not implemented.");
  }

  changeAllDay(){
    this.allDay = !(this.allDay)
    if (this.allDay == false) {
      this.disableShiftStartTime = false;
      this.disableShiftEndTime = false;
    } else {
      this.disableShiftStartTime = true;
      this.disableShiftEndTime = true;
    }
  }


  get enabledWells() {
    return assetsModule.enabledWells;
  }

  async setUserOptions(){
    this.userOptions.push(scheduleModule.accountDetails.map(item=>{
      return { value: item.email, text: item.name }
    })) 
  }
  async getAllAssetOptions(){
    var detailedComponent, currentComponent;
    this.allAssetTypes = {
      "Area": [],
      "Pad": [],
      "Well": [],
      "Route": []
    }
    for (var x = 0; x < this.scheduledRowComponents.length; x++) {
      detailedComponent = this.getFullComponentDetailsByID(this.scheduledRowComponents[x].title_row.selection_id)
      if (detailedComponent != null) {
        this.allAssetTypes[detailedComponent.AssetType].push(detailedComponent.Asset)
        if (this.activeComponent.RowID == detailedComponent.ID)
          currentComponent = detailedComponent;
      }
		}
    // this.allAssetTypes = await scheduleModule.getOperatorAssets();
    this.setItemDetails();
    if (currentComponent)
      this.assetName = currentComponent.Asset
  }
  getFullComponentDetailsByID(id) {
		if (scheduleModule.activePersonnelSchedule != null) {
			// @ts-ignore
			for (var x = 0; x < scheduleModule.activePersonnelSchedule.Rows.length; x++) {
					// @ts-ignore
        if (id == scheduleModule.activePersonnelSchedule.Rows[x].ID) {
          // @ts-ignore
          return scheduleModule.activePersonnelSchedule.Rows[x]
        }
      }
		}
	}

  async setAssetOptions(){
    if (this.allAssetTypes) {
      // this.assetOptions = JSON.parse(this.allAssetTypes[`${this.assetType}s`]) 
      this.assetName = ''
      this.assetOptions = this.allAssetTypes[`${this.assetType}`]
    }
  }

  getStartTime() {
    // for (let index=0; index<scheduleModule.personnelScheduleShifts.length; index++) {
	// 		if (scheduleModule.personnelScheduleShifts[index].ShiftID == this.activeComponent.ID && scheduleModule.personnelScheduleShifts[index].startTime) {
    //     return convertISOtoLocalTime(scheduleModule.personnelScheduleShifts[index].startTime)
	// 		}
	// 	}

    return convertISOtoLocalTime(this.activeComponent.StartTime)
  }

  getEndTime() {
    // for (let index=0; index<scheduleModule.personnelScheduleShifts.length; index++) {
	// 		if (scheduleModule.personnelScheduleShifts[index].ShiftID == this.activeComponent.ID && scheduleModule.personnelScheduleShifts[index].endTime) {
    //     return convertISOtoLocalTime(scheduleModule.personnelScheduleShifts[index].endTime)
	// 		}
	// 	}
    return convertISOtoLocalTime(this.activeComponent.EndTime)
  }

  setItemDetails() {
    if (this.activeComponent) {
      this.shiftStartTime = this.getStartTime()
      this.shiftEndTime = this.getEndTime()
      this.assetType = this.activeComponent.AssetType
      this.setAssetOptions();
      this.userName = this.activeComponent.Username
    }
  }


  getDropdownTimeString(time) {
	function pad(n, width, z) {
		z = z || '0';
		n = n + '';
		return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
	}
	  if (time.getHours() == 0) {
		  return `12:${pad(time.getMinutes(), 2, '0')}am`
	  } else if (time.getHours() < 12) {
		return `${time.getHours()}:${pad(time.getMinutes(), 2, '0')}am`
	  } else if (time.getHours() == 12) {
		return `12:${pad(time.getMinutes(), 2, '0')}pm`
	  } else if (time.getHours() <= 23) {
		  return `${time.getHours() - 12}:${pad(time.getMinutes(), 2, '0')}pm`
	  }
	  return ''
  }

  async created() {
	  this.shiftDate = this.activeComponent.StartTime + "Z"


    this.setUserOptions(),
    this.getAllAssetOptions();
	
	this.setTimeOptions()


	this.shiftStartTime = this.getDropdownTimeString(new Date(this.activeComponent.StartTime + "Z"))
	this.shiftEndTime = this.getDropdownTimeString(new Date(this.activeComponent.EndTime + "Z"))

	
	await this.getShiftTemplates();
      for (let i=0; i<this.shiftTemplates.length; i++) {
		  if (this.activeComponent.TemplateID == this.shiftTemplates[i].ID) {
			  this.selectedTemplate = i
			  break
// this.templateOptions.push({ value: i, text: this.shiftTemplates[i].TemplateName })
		  }
        
      }
    

  }



  setTimeOptions() {
	function pad(n, width, z) {
		z = z || '0';
		n = n + '';
		return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
	}
    for (let i = 0; i <= 23; i++) {
      for (let j = 0; j < 60; j+=15) {

		  if (i == 0) {
			  if (j == 0) {
				  this.timeOptions.push(`12:00am`);
			  } else {
				  this.timeOptions.push(`12:${pad(j, 2, '0')}am`);
			  }
			  
		  } else if (i < 12) {
			  if (j == 0) {
				  this.timeOptions.push(`${i}:00am`);
			  } else {
				  this.timeOptions.push(`${i}:${pad(j, 2, '0')}am`);
			  }
		  } else if (i == 12) {
			  if (j == 0) {
				  this.timeOptions.push(`${i}:00pm`);
			  } else {
				  this.timeOptions.push(`${i}:${pad(j, 2, '0')}pm`);
			  }
		  } else {
			  if (j == 0) {
				  this.timeOptions.push(`${i - 12}:00pm`);
			  } else {
				  this.timeOptions.push(`${i - 12}:${pad(j, 2, '0')}pm`);
			  }
		  }
      }
    }
    // this.shiftStartTime='00:00';
    this.setShiftEndTimeOptions(this.shiftStartTime);
    // this.shiftEndTime='23:00';
  }


  setShiftEndTimeOptions(value) {
    if (typeof value == "string" && value.constructor == String) {
      this.shiftEndTimeOptions = this.timeOptions.slice();
    //   for (let i = 0; i <=this.timeOptions.indexOf(value); i++) {
    //     this.shiftEndTimeOptions.shift();
    //   }
    //   this.shiftEndTime = this.shiftEndTimeOptions[0];
    }
  }



  async getShiftTemplates(){
    this.templateOptions = [{ value: -1, text: 'Custom' }];
    this.shiftTemplates = await scheduleModule.getShiftTemplates();
    if (this.shiftTemplates) {
      for (let i=0; i<this.shiftTemplates.length; i++) {
        this.templateOptions.push({ value: i, text: this.shiftTemplates[i].TemplateName })
      }
    }
  }


}
